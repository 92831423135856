@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
html, body {
  touch-action: manipulation; /* Empêche les gestes de zoom */
  -ms-touch-action: manipulation; /* Empêche les gestes de zoom sur IE */
  font-family: 'Inter';
}

.neon-gauge-wrapper-life {
  display: flex;
  gap: 7px;
  width: 35%;
  margin: 0 auto;
}

.neon-gauge-container-life {
  position: relative;
  flex: 1;
  background: #3A3839;
  border-radius: 25px;
  padding: 0px;
  margin: 7px auto;
  
}

.neon-gauge-life {
  height: 14px;
  border-radius: 20px;
  box-shadow: 0 0px 5px #fff, 0 0 7px #ffff !important;
  background: linear-gradient(90deg, #FEFCE7 50%, #FEFCE7);
  transition: width 0.5s ease-in-out;
}

.text-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Prevent interference with the gauge bar */
}

.neon-gauge-container-life p {
  position: relative;
  z-index: 1; /* Ensure the text stays above the gauge bar */
}