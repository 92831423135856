:root {
  --primary-color: #e0c521;
}
@media only screen and (min-width:1200px) {
  .container {
    max-width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .container {
    max-width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .container {
    max-width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .container {
    max-width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .container {
    max-width: 400px;
  }
}
.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em; /* Ajustez selon vos besoins */
  height: 2.4em; /* 2 lignes * hauteur de ligne */
  width: 100%; /* Assurez-vous que l'élément prend toute la largeur disponible */
}
.recCoin {
  -webkit-box-shadow: 0px 0px 5px 5px #0D5227; 
  box-shadow: 0px 0px 5px 5px #0D5227;
}
.recCoin2 {
  -webkit-box-shadow: 0px 0px 5px 5px #86CAB5; 
  box-shadow: 0px 0px 7px 2px #86CAB5;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 250px;
  border: 2px solid var(--primary-color);
  padding: 0.5rem 0.3rem;
  margin: 13px 10px;
  position: relative;
  border-radius: 5px;
}
.card-referal {
  transform: translate(-50%, 0%);
margin-left: 50%;
}
.centered-elt {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  /* height: 3.255rem; */
}
@media only screen and (min-width:1200px) {
  .centered-elt {
    width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .centered-elt {
    width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .centered-elt {
    width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .centered-elt {
    width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .centered-elt {
    width: 400px;
  }
}
.centered-elt ul {
  width: 100%;
}
.card__title {
  font-weight: 400;
  text-align: center;
}

.card__title .card__price {
  font-weight: bold;
}

.image__container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.image__container img {
  width: 100%;
  object-fit: cover;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card__price {
  text-align: center;
  display: inline-block;
}

.card__badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: rgb(227, 112, 30);
  border-radius: 50%;
  transform: translate(20px, -20px) scale(1);
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(227, 112, 30);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  animation: scaleanimation 1s ease infinite;
  opacity: 0.8;
}

@keyframes scaleanimation {
  to {
    transform: translate(20px, -20px) scale(1.05);
    opacity: 1;
  }
}

.card__badge--hidden {
  display: none;
}


.progress-circle {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #141A25;
  border: 1px solid #5DD387;
  display: flex;
  align-items: center;
  justify-content: center;
  --time-left: 0;
}

.progress-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 25px, 25px, 12.5px);
  background-color: #5DD387;
  animation: rotate-mask calc(var(--time-left) * 1s) linear forwards;
}

.progress-fill {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 12.5px, 25px, 0);
  background-color: #141A25;
  animation: rotate-fill calc(var(--time-left) * 1s) steps(var(--time-left)) linear forwards;
}

.progress-center {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate-mask {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
}

@keyframes rotate-fill {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}