
/* html, body {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none; 
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
} */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
html, body {
  touch-action: manipulation; /* Empêche les gestes de zoom */
  -ms-touch-action: manipulation; /* Empêche les gestes de zoom sur IE */
  font-family: 'Inter' !important;
  height: 100%;
  margin: 0;
  padding: 0;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  /*  overflow: hidden;  */
 /*   touch-action: pan-y !important; 
   -ms-touch-action: pan-y !important; */
   user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

@font-face {
  font-family: 'Squartiqa';
  src: url('./fonts/Squartiqa4F.ttf');
  font-weight: normal;
  font-style: normal;
}
.fontRobot {
  font-family: 'Squartiqa';
}

.fontInt{
  font-family: 'Inter';
}
::-webkit-scrollbar {
  display: none;
}

.blocked {
  overflow-y: hidden !important;
  height: var(--tg-viewport-height);
  scrollbar-width: 0;
}
.main-content {
  height: var(--tg-viewport-height); /* Ajustez 60px à la hauteur exacte de votre footer */
    overflow-y: auto !important; /* Permet le défilement vertical */
    -webkit-overflow-scrolling: touch !important; /* Pour un défilement fluide sur iOS */
    scrollbar-width: 0;
 /*  height: 100vh; */ /* Fixe la hauteur à 100% de la hauteur de la fenêtre */
  /* overflow-y: auto; */ /* Permet le défilement vertical */
  /* -webkit-overflow-scrolling: touch; */ /* Pour un défilement fluide sur iOS */
}

body {
  overscroll-behavior: none; /* Empêche les comportements de défilement excessifs */
  height: 100%;
}

* {
  user-select: none; /* Empêche la sélection de texte */
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}
.no-scroll {
  overflow: hidden !important;
}
/* html, body {
  touch-action: none; 
  -ms-touch-action: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
} */

.scrollable {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important; /* Smooth scrolling on iOS */
}
.airdrpImg{

/*   background-image:
  radial-gradient(
    circle,
    #00A600 20%,
    #10111A 45%
    
  ); */

}
/* html, body {
  height: 100%; 
  margin: 0;
  touch-action: none; 
  -ms-touch-action: none;
} */

 .full-screen-exch {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background-color: #141414 !important; 
 /*  width: 100vw;
  /* height: 100vh; */
 /*  overflow: hidden; */
}

.full-screen-settings {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* overflow: hidden; */
  background-color: #141414 !important; 
 /*  width: 100vw;
  /* height: 100vh; */
 /*  overflow: hidden; */
}
.MenuImg{
  margin-left: calc(50% - 150px);
  margin-top:0;
  background-color: #141414 !important; 
}
/* .section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  background: linear-gradient(to left, #1D674B, #133B3B);
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.section-container-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  background: linear-gradient(to top, #1B9C51, #133B3B);
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.section-container-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  background: linear-gradient(to left, #1B9C51, #3DC471);
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
} */

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  pointer-events: none;
}

p, h1, h2, h3, h4, h5, h6, div, span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
/*   -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;  */
  background-color: #141414;
  color: #fff;
}

input, select, textarea {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
:root:root {
  --adm-button-border-radius: 2px;
  --adm-color-primary:#fff;
  --active-line-height:30px;
  --adm-border-color:#010101
  --adm-color-background: #141414;
  --adm-color-weak: #141414;
  --adm-color-box: #141414;

}
.bgAllColor{
  background-color: #010101;
}
.bgClickColor{
  background-color: #010101;
}/* 181D19 */
.headerColor{
  background-color: #010101;  
}
.bottom-btn{
  /* transform: translate(-50%, 0%); */
  transform: translate(-50%,0%) !important;
  left:50%;
  z-index: 1000;

}
.contentTabs {

  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  user-select: none;
}
.touchable {
  transition: opacity 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.touchablerock {
  transition: opacity 1s ease-in-out, box-shadow 0.2s ease-in-out;
  }
.touchablerock:active {
  opacity: 1; /* Diminue l'opacité pour l'effet de toucher */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Ajoute une ombre pour l'effet de profondeur */
}

.touchable:active {
  opacity: 0.6; /* Diminue l'opacité pour l'effet de toucher */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Ajoute une ombre pour l'effet de profondeur */
}
.white-neon {
  border: solid 0px #fff;
  box-shadow:  0 0 0px #324D48, 0 0 0px #1B9C51;
}
.cards__container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.neon-container {

border: solid 2px #36C386;
box-shadow:  0 0 0px #36C386, 0 0 10px #36C386;

}
.custom-passcode-input .adm-passcode-input-cell {
  background-color: #233132; /* Couleur de fond des cases */
}
.heading{
  text-align: center;
}
.card-referal {
  transform: translate(-50%, 0%);
margin-left: 50%;
}
@keyframes clignoter {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.isClignot{
  animation: clignoter 1s infinite;
}
.centered-elt {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  /* height: 3.255rem; */
}
@media only screen and (min-width:1200px) {
  .centered-elt {
    width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .centered-elt {
    width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .centered-elt {
    width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .centered-elt {
    width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .centered-elt {
    width: 400px;
  }
}
.centered-elt ul {
  width: 100%;
}

.footer-nav-area-bis {



  z-index: 1000;



  /* background-color: #ffffff; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer-nav-area-tris {



  z-index: 1000;


  -webkit-box-shadow: 0px 0px 0.5rem 0px rgba(15, 15, 15, 0.15);
          box-shadow: 0px 0px 0.5rem 0px rgba(15, 15, 15, 0.15);
  /* background-color: #ffffff; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-around;
      -ms-flex-pack: space-around;
          justify-content: space-around;
}
[theme-color=dark] .footer-nav-area-bis {

}

.suha-footer-nav-bis {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  /* height: 3.255rem; */
}
@media only screen and (min-width:1200px) {
  .suha-footer-nav-bis {
    width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .suha-footer-nav-bis {
    width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .suha-footer-nav-bis {
    width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .suha-footer-nav-bis {
    width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .suha-footer-nav-bis {
    width: 400px;
  }
}
.suha-footer-nav-bis ul {
  width: 100%;
}
.suha-footer-nav-bis ul li {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  position: relative;
  z-index: 1;
}
.suha-footer-nav-bis ul li a {
  position: relative;
  display: block;
  font-size: 0.625rem;
  text-align: center;
/*   text-transform: capitalize; */
  line-height: 1;
  color: #020310;
  z-index: 1000;
}
.suha-footer-nav-bis ul li a i {
  display: block;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}
.suha-footer-nav-bis ul li a:hover {
  color: #625AFA;
}
.suha-footer-nav-bis ul li a img {
  max-height: 1.5rem;
  margin: 0 auto 0.25rem;
  display: block;
}
[theme-color=dark] .suha-footer-nav-bis ul li a {
  color: #ffffff;
}



@-webkit-keyframes darkModeBGMove {
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes darkModeBGMove {
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
.adm-tabs-header{
  border-bottom:solid 0px #fff !important;
}
.adm-tabs-tab-line{
  box-shadow: 0 0px 5px #5DE9B3, 0 0 5px #5DE9B3 !important;
}
.neon-gauge-container {
  width: 100%;
  background: #3B3654;
  border-radius: 25px;
  padding: 0px;
  margin: 7px auto;
}

.neon-gauge {
  height: 10px;
  border-radius: 20px;
  background:  linear-gradient(90deg, #fff, #30B45C);
  box-shadow:0 0px 2px #44A563, 0 0 6px #44A563 !important;
  transition: width 0.5s ease-in-out;
}
.bgBalance{

  background:  linear-gradient(28deg, #141A25, #152224);
/* 152224 */
}
.ripple-container-ripple {
  position: relative;
  display: inline-block;
  transform: translate(-50%,0%);
  margin-left: 50%;
}

.golden-coin {
  position: relative;
  z-index: 1;
  width: 180px; /* Ajustez la taille selon vos besoins */
}
.coinFetcher {
 /* Optional: if you want rounded corners */
}


.ripple {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -150px;
  border-radius: 50%;
  background-image:
  radial-gradient(
    circle,
    #020310 20%,
    #0ff 300%
  );
  animation: ripple-animation 4s infinite;
}
.coinShadow{
   background-image:
   radial-gradient(
    circle at center,
    #9E8021 ,
    #0F0013 
  );
}

.ripple:nth-child(2) {
  animation-delay: 1.5s; /* Ajustement du délai pour lisser l'effet */
}

.ripple:nth-child(3) {
  animation-delay: 3s; /* Ajustement du délai pour lisser l'effet */
}

@keyframes ripple-animation {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  60% { /* Les ripples disparaissent encore plus tôt */
    transform: scale(1.2); /* Diminution de la taille maximum */
    opacity: 0;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.neon-text-container {
  font-size: 21px;
  font-weight: 600;
  
}

.neon-text {
  color: #fff;
  font-size: 2em;
  text-align: center;
  text-shadow: 
    0 0 2px #fff,
    0 0 3px #fff;
}
.block {
  position: relative;


  background: linear-gradient(0deg, #000, #272727);
}

.block:before, .block:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 15px !important;
  background: linear-gradient(45deg, #60441E, #2E9495, #A3A5B4,#241D14, #63A351, #EECC73, 
    #F3CA33, #5DE9B3,#A74D31, #fff);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.block:after {
  filter: blur(50px);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Inter UI';
  font-style: normal;
  font-weight: 300;
  src: url("https://rsms.me/inter/font-files/Inter-Regular.woff2?v=3.5") format("woff2"), url("https://rsms.me/inter/font-files/Inter-Regular.woff?v=3.5") format("woff");
}

.right {
  position: absolute;
  right: 0;
  width: 60vw;
  height: 100%;
  overflow: hidden;
}
.center-ccard {
  position: absolute;
  top: 120px !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
}
.ccard {
  width: 320px;
  height: 190px;
}
.cflip {
  width: inherit;
  height: inherit;
/*   transition: 0.7s; */
/*   transform-style: preserve-3d;
  -webkit-animation: flip 2.5s ease;
          animation: flip 2.5s ease; */
}
.front,
.back {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 15px;
  box-shadow: 0 1px 3px 0px rgba(0,0,0,0.3);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-image: linear-gradient(to right, #f5f5f5, #f7f7f7);
  overflow: hidden;
}
.front {
  transform: translateZ(0);
}
.apple-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 48px;
  height: 48px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.chip {
  position: absolute;
  top: 110px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 40px;
  border: 1px solid #666;
  border-radius: 9px;
  background-color: #ddd;
  overflow: hidden;
}
.chip .pin {
  position: absolute;
  width: 22px;
  height: 10px;
  border-radius: 5px;
  border: 1px solid #555;
}
.chip .pin:nth-child(1) {
  top: 3px;
  left: 4px;
}
.chip .pin:nth-child(2) {
  top: 14px;
  left: 4px;
}
.chip .pin:nth-child(3) {
  top: 25px;
  left: 4px;
}
.chip .pin:nth-child(4) {
  top: 3px;
  right: 4px;
}
.chip .pin:nth-child(5) {
  top: 14px;
  right: 4px;
}
.chip .pin:nth-child(6) {
  top: 25px;
  right: 4px;
}
.card-holder {
  position: absolute;
  top: 130px;
  left: 5px;
  margin: 0px 25px;
  font-size: 20px;
  letter-spacing: 0.5px;
  color: #555;
}
.master {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
}
.master .circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #999;
}
.master .master-yellow {
  margin-left: -20px;
}
.master .master-text {
  position: absolute;
  top: 53px;
  left: 8px;
  font-size: 12px;
  color: #666;
}
.ccard {
  /* perspective: 1000; */
}
.ccard:active .cflip {
  /* transform: rotateY(180deg); */
}
.back {
 /*  transform: rotateY(180deg) translateZ(0); */
}
.back .goldman-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 90px;
}
.back .strip-black {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 50px;
  background: #555;
}
.info {
  position: absolute;
  width: 270px;
  margin: 50px;
  text-align: center;
  color: #aaa;
  font-family: Helvetica;
}
.js-tilt-glare > .js-tilt-glare-inner {
  background-image: linear-gradient(0deg, rgba(255,255,255,0) 70%, #fff 100%);
  width: 220px;
  height: 220px;
}
@-webkit-keyframes flip {
  0%, 100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}
@keyframes flip {
  0%, 100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}

/* styles.css */
.wallet-button {

  left:50%;
  transform: translate(-50%,0%);
  width: calc(100% - 20px);
}

.wallet-button:hover {

}

.styles_box {
  background: #eee;
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 8px;
  &.dark {
    background: #666666;
  }
}
/* Improved touch-point styling */
/* .touch-point {
  position: absolute;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  pointer-events: none;
  animation: fadeUp 2.5s forwards cubic-bezier(0.25, 0.8, 0.25, 1);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
} */

/* Improved image styling */
.touchable {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  will-change: transform, opacity; /* Optimize for performance */
  backface-visibility: hidden;
}

.touchable:active {
  opacity: 0.8; /* Add slight transparency on click */
  transform: scale(0.98); /* Add slight scale down on click for feedback */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
}

@keyframes fadeUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-40px); /* Increase translation to go higher */
    opacity: 0;
  }
}

/* Animation keyframes */
/* @keyframes tongueMenuAnimation {
  0% { background-position: 45% 0%; }
  50% { background-position: 56% 100%; }
  100% { background-position: 45% 0%; }
}

@keyframes jelly-touch {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.05, 0.95, 1);
  }
  40% {
    transform: scale3d(0.98, 1.02, 1);
  }
  50% {
    transform: scale3d(1.05, 0.95, 1);
  }
  65% {
    transform: scale3d(0.98, 1.02, 1);
  }
  75% {
    transform: scale3d(1.02, 0.98, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.gradient-animation {
  animation: tongueMenuAnimation 4s ease infinite, jelly-touch 0.9s both;
} */

.wolf-touchable {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  will-change: transform, opacity; /* Optimize for performance */
  backface-visibility: hidden;
  position: relative; /* Ensure it is above the background */
  z-index: 5; /* Ensure image is above the background but below touch points */
}

.wolf-touchable:active {
  opacity: 0.8; /* Add slight transparency on click */
  transform: scale(0.98); /* Add slight scale down on click for feedback */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
}

.touch-point {
  position: absolute;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  pointer-events: none;
  animation: fadeUp 4.5s forwards cubic-bezier(0.25, 0.8, 0.25, 1); /* Custom easing for smoother animation */
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add shadow for better readability */
  z-index: 10; /* Ensure touch points are above other elements */
}

/* Animation keyframes */
@keyframes tongueMenuAnimation {
  0% { background-position: 45% 0%; }
  50% { background-position: 56% 100%; }
  100% { background-position: 45% 0%; }
}

@keyframes jelly-touch {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.05, 0.95, 1);
  }
  40% {
    transform: scale3d(0.98, 1.02, 1);
  }
  50% {
    transform: scale3d(1.05, 0.95, 1);
  }
  65% {
    transform: scale3d(0.98, 1.02, 1);
  }
  75% {
    transform: scale3d(1.02, 0.98, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

/* Animation class */
.gradient-animationg {
  animation: tongueMenuAnimation 4s ease infinite, jelly-touch 0.9s both;
}

@keyframes coinAnimation {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(calc(50vw - 50%), calc(100vh - 50%));
    opacity: 0;
  }
}

/* .coin-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;

  z-index: 9000;
  transform: translate(-50%, -50%);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
} */
.coin-animation-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Ensure this doesn't block other interactions */
}

.coin-animation {
  position: absolute;
  width: 30px;
  height: 30px;
  transition: transform 1.5s ease, opacity 1.5s ease;
  z-index: 1000;
  /* Remove these lines to let JS set initial positions
  left: 50%; 
  top: 50%;
  */
}

/* Désactiver le défilement */

/* Désactiver le zoom */
.no-zoom {
  touch-action: none; /* Désactiver les actions tactiles par défaut */
  -ms-touch-action: none; /* Désactiver les actions tactiles par défaut pour IE */
}

.no-zoom {
  -ms-content-zooming: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.no-zoom {
  overscroll-behavior: none; /* Désactiver les actions de défilement */
}

/* Désactiver le zoom sur mobile */
@viewport {
  width: device-width;
  zoom: 1;
  user-zoom: fixed;
}

@-ms-viewport {
  width: device-width;
  zoom: 1;
  user-zoom: fixed;
}

.purle-circle {
  border-radius: 50%;
  background-color: #7756D3;
  height: 5px;
  width: 5px;
}

.green-circle {
  border-radius: 50%;
  background-color: #57D48D;
  height: 5px;
  width: 5px;
}
.white-circle {
  border-radius: 50%;
  background-color: #fff;
  height: 5px;
  width: 5px;
}

.hiddenGr { 
  opacity: 0;
  display: none;
  transform: translateY(-10px);
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  
}

.visibleGr{
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.2s ease-out, transform 0.2s ease-in;

}

.hiddenG { 
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transform: translateY(-10px);
  transition: max-height 0.2s ease-out, transform 0.2s ease-out, visibility 0.2s ease-out;
}

.visibleG {
  max-height: 135px; /* La hauteur de l'élément quand il est visible */
  visibility: visible;
  transform: translateY(0);
  transition: max-height 0.2s ease-out, transform 0.2s ease-in, visibility 0.2s ease-in;
}

.error-animation {
  animation: shake 0.3s ease-in-out;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}


.restriction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  text-align: center;
  padding: 20px;
}

.restriction-content {
  max-width: 400px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.restriction-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-self: center;
  margin-top: 125px;
  background-color: #131313;
  text-align: center;
  padding: 20px;
}

.restriction-content2 {
  max-width: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #222222;
  justify-content: center;
  align-self: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.restriction-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.restriction-content h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.restriction-content p {
  font-size: 18px;
  color: #666;
}

.img-rotate {
  transform: rotate(180deg);
}