/* .footer-nav-area {
  position: fixed;
  transform: translate(-50%, 0%);
  z-index: 1000;
  border:solid 1px #191D21;
  left: 50%;
  max-width: 96%;
  bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-shadow: 0px 0px 8px 2px #19573F; 
  box-shadow: 0px 0px 7px 2px #19573F;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

} */
.footer-nav-area {
  position: fixed !important;
  transform: translate(-50%, 0%);
  overflow: hidden !important;

/*   border-top: solid 1.5px #57D48D;
  border-left: solid 0.5px transparent;
  border-right: solid 0.5px transparent; */

  /* border-bottom: solid 0.5px transparent; */
  left: 50%;
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: center;
  background-color: #19332D;
/*   backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px); */
  border-radius: 0px;
  background-clip: padding-box;
overflow: hidden;
z-index: 99999;
  /* box-shadow: 0px 0px 7px 2px #57D48D; */
}


.footer-nav-area::before, .footer-nav-area::after {
/*   content: '';
  position: absolute;
  top: 1px;
  bottom: 1px; 
  width: 1px;
  background: linear-gradient(to bottom, #57D48D, black);
  border-radius: 10px; */
}

/* .footer-nav-area::before {
  left: 1px;
}

.footer-nav-area::after {
  right: 1px; 
} */
.bkgSelect{
  background-color: transparent;
  /* border:solid 1.5px #77694C; */
/*   background: linear-gradient(to bottom, #23403F, #2ECC95); */ 
}
.bkgNone{
  background-color: transparent;
}

.iconSelected{
  /* background-color: aqua; */
  /* padding:0px;
  border-radius: 10px;
  background-color: #052713; */
/*   -webkit-box-shadow: 0px 0px 8px 2px #fff; 
  box-shadow: 0px 0px 7px 2px #fff; */
}
.iconSelected2{
  padding:0px;
  border-radius: 10px;
  background-color: #163C29;
/*   -webkit-box-shadow: 0px 0px 8px 2px #fff; 
  box-shadow: 0px 0px 7px 2px #fff; */
}
.icgonSelected {
  transition: opacity 0.3s ease-in-out;
}

.iconSelected:active {
  opacity: 1; /* Target opacity value */
}
.iconUnselected{
  opacity: 0.5;
}


.bkFoot{
  
  position: fixed !important; /* Make sure the footer is fixed at the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it's above other content */
}
.suha-footer-nav {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  /* height: 3.255rem; */
}
@media only screen and (min-width:1200px) {
  .suha-footer-nav {
    width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .suha-footer-nav {
    width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .suha-footer-nav {
    width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .suha-footer-nav {
    width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .suha-footer-nav {
    width: 400px;
  }
}
.suha-footer-nav ul {
  width: 100%;
}
.suha-footer-nav ul li {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  position: relative;
  z-index: 1;
}
.suha-footer-nav ul li a {
  position: relative;
  display: block;
  font-size: 0.625rem;
  text-align: center;
  text-transform:none;
  color: #020310;
  z-index: 1000;
}
.suha-footer-nav ul li a i {
  display: block;
}
.suha-footer-nav ul li a:hover {
  color: #625AFA;
}
.suha-footer-nav ul li a img {
  max-height: 1.5rem;
  margin: 0 auto 0.25rem;
  display: block;
}
[theme-color=dark] .suha-footer-nav ul li a {
  color: #ffffff;
}
