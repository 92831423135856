.global {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .wolf-rocket-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    background-image: radial-gradient(circle at center 60%, #131F2C -20%, #0F111A 100%);
    color: white;
    padding: 20px 20px 0px 20px;
  }
  
  .rocket-display {
    width: 100%;
    height: 70%;
    position: relative;
    overflow: hidden;
   /*  background: #0F111A; */
   /*  backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); */
    border: 0px solid #56D38D;
   /*  -webkit-box-shadow: 0px 0px 10px 2px #56D38D; */
    /* box-shadow: 0px 0px 10px 2px #544c40; */
    border-radius: 10px;
    padding: 10px;
  }
  .launchBtn{
    box-shadow: 0px 0px 3px 2px #726981;
    -webkit-box-shadow: 0px 0px 3px 2px #726981;
  }
  .rocket-image {
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: center;
    
  }
  
  .rocket-flight .rocket-image {
    animation: rocketLaunch 3s ease-out forwards;
    
  }
  
  .explosion-image {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  @keyframes rocketLaunch {
    0% {
      bottom: 0;
      left: 0;
      transform: rotate(0deg) rotate(45deg);
    }
    50% {
      bottom: 50%;
      left: 50%;
      transform: translate(-50%,50%) rotate(45deg);
    }
    100% {
      bottom: 50%;
      left: 50%;
      transform:translate(-50%,50%)  rotate(45deg);
    }
  }

  .rocket-pre-flight .rocket-image {
    animation: rocketPreLaunch 3s ease-out forwards;
  }
  @keyframes rocketPreLaunch {
    0% {
      bottom: 0;
      left: 0;
      transform: rotate(0deg);
    }
    50% {
        bottom: 0;
        left: 0;
      transform:  rotate(22.5deg);
    }
    100% {
        bottom: 0;
        left: 0;
      transform:rotate(45deg);
    }
  }
  
  .smoke-trail {
    width: 2px;
    height: 100px;
    background: gray;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: smoke 1s infinite;
  }
  
  @keyframes smoke {
    0% {
      height: 0;
      opacity: 1;
    }
    100% {
      height: 100px;
      opacity: 0;
    }
  }
  
  .moon-image {
    position: absolute;
    top: -20px;
    right: -20px;
    transition: width 0.1s ease-out, height 0.1s ease-out, top 3s ease-in-out, right 3s ease-in-out;
  }
  
  .move-moon {
    top: calc(100% - 50px);
    right: calc(100% - 50px);
    width: 0;
    height: 0;
  }
  
  .controls {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
  }
  
  .bet-input {
    width: 45%;
  }
  
  .launch-button,
  .quit-button {
    width: 45%;
  }
  
  .status {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 7px;
  }
  
  .bottomSec {
    width: calc(100% + 40px);
/*     border-bottom: solid 0px #fff;
    border-left: solid 2px #242734;
    border-right: solid 0px #242734; */
    /* background-color: #241d14; */
    padding-bottom: 30px;
    /* border-top: 1px solid #77694c;
    -webkit-box-shadow: 0px 0px 10px 2px #544c40;
    box-shadow: 0px 0px 10px 2px #544c40; */
  }
  
  .neonJauge {
  }


  
  
  .meteor-1 {
    position: absolute;
    top: 202px;
    left: 90%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 8.3s linear infinite;
    
    animation-fill-mode: forwards;

  }
  .meteor-1:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
    
  }
  
  .meteor-2 {
    position: absolute;
    top: 59px;
    left: 24%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 9.3s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-2:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-3 {
    position: absolute;
    top: 103px;
    left: 30%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 5.1s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-3:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-4 {
    position: absolute;
    top: 143px;
    left: 44%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 7.3s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-4:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-5 {
    position: absolute;
    top: 179px;
    left: 44%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 6.4s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-5:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-6 {
    position: absolute;
    top: 183px;
    left: 58%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 9s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-6:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-7 {
    position: absolute;
    top: 122px;
    left: 29%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 3.6s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-7:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-8 {
    position: absolute;
    top: 281px;
    left: 16%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 6.8s linear infinite;
    animation-fill-mode: forwards;

  }

  .meteor-8:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-9 {
    position: absolute;
    top: 253px;
    left: 78%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 9s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-9:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-10 {
    position: absolute;
    top: 55px;
    left: 62%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 5.9s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-10:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-11 {
    position: absolute;
    top: 220px;
    left: 90%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 7.6s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-11:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-12 {
    position: absolute;
    top: 144px;
    left: 99%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 6.9s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-12:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-13 {
    position: absolute;
    top: 138px;
    left: 62%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 7.9s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-13:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-14 {
    position: absolute;
    top: 59px;
    left: 58%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 4.2s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-14:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  .meteor-15 {
    position: absolute;
    top: 275px;
    left: 65%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor 7.9s linear infinite;
    animation-fill-mode: forwards;

  }
  .meteor-15:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 5px;
    border-radius: 50%;
    margin-top: -2px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px 3px #fff;
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  .countdown {
    font-size: 38px;
   text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .countdown.active {
    opacity: 1;
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
.circlePos{
    animation: pulse 1s infinite;
    transform: translate(-50%,-50%) !important;
    margin-left: 50%;
    margin-top: 50%;
   /*  background-image: radial-gradient(circle at center, #181729  -10%, #181729 100%); */
    /* border: 1px solid #56D38D; */
   /*  -webkit-box-shadow: 0px 0px 15px 10px #ffff;
    box-shadow: 0px 0px 15px 10px #fff; */
}

.neon-text2 {
    color: #fff;
    text-shadow: 
      0 0 1px #fff,
      0 0 2px #fff;
  }
  /*    animation: pulse 1s infinite; */

  /* if(hasShownFail){
          setIsOpenFail(true)
          hasShownFail.current = false; // Marque que le modal a été affiché
        } */
.touchable-rock {
transition: opacity 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.touchable-2 {
    transition: opacity 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }

  .explosion {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1);
    animation: explode 1s ease-out forwards;
  }
  
  @keyframes explode {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(0.1);
    }
    50% {
      opacity: 0.8;
      transform: translate(-50%, -50%) scale(2);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(3);
    }
  }
  
  .explosion::before,
  .explosion::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #EB5924;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1);
    animation: explode-particles 3s ease-out forwards;
  }
  
  .explosion::before {
    animation-delay: 0.2s;
  }
  
  .explosion::after {
    animation-delay: 0.4s;
  }
  .multiplierT{
    transform: translate(-50%,0%) !important;
    animation: pulse-2 1s infinite;
  }
  .lostT{
    transform: translate(-50%,-50%) !important;
    animation: pulse-3 2s ;
    margin-left:50%;
    margin-top:50%;
    width:70%
  }
  @keyframes pulse-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes pulse-3 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes explode-particles {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(0.1);
    }
    20% {
        opacity: 0.6;
        transform: translate(-50%, -50%) scale(1.5);
      }
      60% {
        opacity: 1.2;
        transform: translate(-50%, -50%) scale(3);
      }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(2.5);
    }
  }

  .rocket-trail3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 55%; /* Ajustez la hauteur pour couvrir toute la distance */
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transform: translate(50%, 50%) rotate(45deg); /* Ajustez le translate pour déplacer le trait vers la fusée */
    animation: trail-animation 1s infinite;
  }
  .rocket-trail23 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 110%; /* Ajustez la hauteur pour couvrir toute la distance */
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    transform: translate(50%, 50%) rotate(45deg); /* Ajustez le translate pour déplacer le trait vers la fusée */
    /* animation: trail-animation 1s infinite; */
  }
  
  
  @keyframes trail-animation {
    0% {
      opacity: 0;
      height: 50px; /* Ajustez la hauteur initiale si nécessaire */
    }
    50% {
      opacity: 1;
      height: 110%; /* Ajustez la hauteur intermédiaire si nécessaire */
    }
    100% {
      opacity: 0;
      height: 50px; /* Ajustez la hauteur finale si nécessaire */
    }
  }
  .section-disabled {
    pointer-events: none;
    opacity: 0.5; /* Optional: To give a visual cue that the section is disabled */
  }