/* LoadingPage.css */
.loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #131313; /* Facultatif, pour une transition plus douce */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9999; /* Assurez-vous qu'il soit au-dessus de tout le reste */
  }
  
  .loading-page img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }