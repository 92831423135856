.daily-reward {
    text-align: center;
    padding: 0px 20px 20px 20px;
  }
  
  .rewards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .reward {
    width: 60px;
    height: 80px;
    margin: 10px;
    border: 0px solid #555;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    background-color: #1A1A30;
  }
  
  .reward.claimed {
    background-color: #4caf50;
    color: white;
    font-weight: 700;
    font-family: 'Inter';
  }