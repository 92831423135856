/* styles.css */

.globe-container {
    position: relative;
    width: 100%;
    /* height: 140px;*/
    max-width: 600px; /* Adjust based on your requirement */
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
/*     border: solid 2px #5DE9B3;
    box-shadow:  0 0 0px #5DE9B3, 0 0 10px #5DE9B3; */
  }
  
  
.title {
  font-size: 30px; /* Adjust based on your requirement */
  font-weight: bold;
  margin-left: 15px;
  line-height: 27px;
  margin-right: 15px;
  margin-top:10px;
  padding-bottom:10px;
  background: linear-gradient(to bottom, #fff, #131419); /* Change colors as needed */
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: -20px; /* Adjust based on your requirement */
  text-align: center;
}
  
  .globe-canvas {
    width: 200px;
    height: 200px; /* Adjust based on your requirement */
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    overflow: hidden;
/*     margin-left:70px; */

  }
  .center_btn{
    transform: translate(-50%,0%) !important;
    left:50% !important;
  }
 