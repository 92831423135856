.conversion-module {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background-color: #0F111A;
  padding: 15px;
  border-radius: 12px;
}

.conversion-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.conversion-input {
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 0px;
  position: relative;
}
.control-select-2 {
  background-color: transparent;
  border: none;
  text-align: right;
  border-bottom: 0px solid #4A4A4A;
  color: white;

  font-size: 16px;

  cursor: pointer;
}

.control-select-2::-ms-expand {
}
.conversion-input  {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  background-color: #18212F;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-align: right;
}

.conversion-currency {
  position: absolute;
  right: 10px;
  color: #fff;
  font-weight: bold;
}

.conversion-switch {
  cursor: pointer;
  font-size: 24px;
  color: #fff;
  padding: 0 15px;
}

.conversion-button {
  width: 100%;
  margin-top: 15px;
  padding: 12px 0;
  background-color: #ff00ff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.conversion-button:hover {
  background-color: #e600e6;
}