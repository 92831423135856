:root {
  --primary-color: #e0c521;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 250px;
  border: 2px solid var(--primary-color);
  padding: 0.5rem 0.3rem;
  margin: 13px 10px;
  position: relative;
  border-radius: 5px;
}
.neon-progress-container-level {
  width: 100%;
  background: #2F2D3F;
  border-radius: 25px;
  padding: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  margin: 20px auto;
}

.neon-progress-bar-level {
  height: 5px;
  border-radius: 20px;
  background: #5DE9B3;
  box-shadow: 0 0 5px #5DE9B3, 0 0 6px #5DE9B3;
  transition: width 0.5s ease-in-out;
  
  
}

.card__title {
  font-weight: 400;
  text-align: center;
}

.card__title .card__price {
  font-weight: bold;
}

.image__container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.image__container img {
  width: 100%;
  object-fit: cover;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card__price {
  text-align: center;
  display: inline-block;
}

.card__badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: rgb(227, 112, 30);
  border-radius: 50%;
  transform: translate(20px, -20px) scale(1);
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(227, 112, 30);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  animation: scaleanimation 1s ease infinite;
  opacity: 0.8;
}

@keyframes scaleanimation {
  to {
    transform: translate(20px, -20px) scale(1.05);
    opacity: 1;
  }
}
@media only screen and (min-width:1200px) {
  .container {
    max-width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .container {
    max-width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .container {
    max-width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .container {
    max-width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .container {
    max-width: 400px;
  }
}
.card__badge--hidden {
  display: none;
}
.radialBkg {
  z-index: 0;
  background-color: red;
  border-radius: 1000px;
  background-image:
    radial-gradient(
      circle closest-side,
      #4E59DC,
      #2B3149
    );

}
.bkgFlat {
  background-color: transparent;
}
.borsderffBdk {
  border: 0px solid transparent;
  margin-bottom:-10;
  background-size: cover;
  background-repeat: no-repeat;

  background-image: url('https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721229266/milostudio_floor_ground_--ar_1019_8cbcaea5-e4f4-439b-be38-8f499e7853a4_0_copie_1_n74okg.png');

  /* overflow: hidden; */
  background-position: bottom;

}
.borsderBdk {
  position: relative;
  margin-bottom: -10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.borsderBdk::before {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) -10%, #10111A 100%), 
              url('https://res.cloudinary.com/dbt1oxzwd/image/upload/v1721229266/milostudio_floor_ground_--ar_1019_8cbcaea5-e4f4-439b-be38-8f499e7853a4_0_copie_1_n74okg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  opacity: 0.7;
  z-index: -1;
}
/* .containerImg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(24, 29, 25, 0) 50%);
}

.imgBkg {
  position: relative;

  overflow: hidden; 
}

.imgBfkg img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: 1; 
}

.centerPerso{
  transform: translate(-50%, 0%);
  margin-left: 50%;
} */


.containerImg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.imgBfkg {
  position: relative;
  display: inline-block;
  background: radial-gradient(circle at 50% 100%, rgba(255, 255, 255, 0.4) 0%, rgba(24, 29, 25, 0) 45%);
  /* overflow: visible; */
  transition: background 0.3s ease;
}

.imgBfkg.touched {
  background: radial-gradient(circle at 50% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(24, 29, 25, 0) 45%);
} 






.imgBkg img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajuste l'image pour couvrir la totalité du conteneur */
  z-index: 1; /* Place l'image au-dessus du dégradé */
}
.centerPerso{
  transform: translate(-50%, 0%);
  margin-left: 50%;
}
/* .coin {
  position: absolute;
  animation: moveToFetcher 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards, fadeOut 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards;
  pointer-events: none; 
  z-index: 3000;
} */
.coin {
  position: absolute;
  animation: moveToFetcher 2s cubic-bezier(0.33, 1, 0.68, 1) forwards;
  pointer-events: none; /* Pour s'assurer que l'animation ne perturbe pas les interactions */
  z-index: 3000; /* Assurez-vous qu'il apparaît au-dessus de l'image */
}




@keyframes moveToFetcher {
  0% {
      opacity: 1;
      transform: translate(0, 0);
  }
  15% {
      opacity: 0.9; /* Rendre le coin plus visible au virage */
      transform: translate(var(--initial-end-x), var(--initial-end-y));
  }
  100% {
      opacity: 0; /* Le coin disparait complètement en atteignant coinFetcher */
      transform: translate(var(--final-end-x), var(--final-end-y));
  }
}