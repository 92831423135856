.controls-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 20px;
    padding: 10px 0;
  }
  
  .control-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #B0B0B0;
  }
  
  .control-label {
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  
  .select-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .control-select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #4A4A4A;
    color: white;
    padding: 5px 0px;
    font-size: 16px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
  }


  .custom-capsule-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #131313;
    padding: 10px;
    border-radius: 25px;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .tabs {
    display: flex;
    gap: 10px;
  }
  
  .tab {
    padding: 5px 15px;
    background-color: #131313;
    color: #fff;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 12px;
    flex: 0 0 auto;
    font-weight: 500;

  }
  
  .tab.active {
    background-color: #202630;
    color: #fff;
  }
  .tab:not(.active) {
   opacity: 0.5;
  }
  
  .tab:not(.active):hover {
    background-color: #2c2f36;
  }
.bkgSlv{
    background:radial-gradient(circle, #FCF6F4 20%, #A9AAAD);
}
  .control-timeline2 {
    background:linear-gradient(to right, #FCF6F4, #A9AAAD);
    
    min-width: 25vw;
    border: none;
    border-radius: 7px;
    color: #0F111A;
    padding: 9px 12px 9px 12px;
    font-size: 15px;
    font-weight: 600;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    text-align: center;
  }
  .control-timeline {
    background-color: #0F111A;
    min-width: 25vw;
    border: 1.5px solid #3A3839;
    border-radius: 7px;
    color: white;
    padding: 9px 12px 9px 12px;
    font-size: 15px;
    font-weight: 600;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    text-align: center;
  }
  .control-select::-ms-expand {
    display: none;
  }
  
  .input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:7.5px;
  }
  
  .control-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #4A4A4A;
    color: white;
    text-align: left;
    font-size: 16px;
    width: 60px;
    margin-bottom: 5px;
  }
  
  .max-text {
    font-size: 10px;
    color: #6D6D6D;
  }
  
  .control-group .control-select,
  .control-group .control-input {
    width: 80px;
  }
  
  .control-group .control-select:focus,
  .control-group .control-input:focus {
    outline: none;
    border-color: #FFFFFF;
  }

  