:root {
  --primary-color: #e0c521;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 250px;
  border: 2px solid var(--primary-color);
  padding: 0.5rem 0.3rem;
  margin: 13px 10px;
  position: relative;
  border-radius: 5px;
}

.card__title {
  font-weight: 400;
  text-align: center;
}

.card__title .card__price {
  font-weight: bold;
}

.image__container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.image__container img {
  width: 100%;
  object-fit: cover;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card__price {
  text-align: center;
  display: inline-block;
}

.card__badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: rgb(227, 112, 30);
  border-radius: 50%;
  transform: translate(20px, -20px) scale(1);
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(227, 112, 30);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  animation: scaleanimation 1s ease infinite;
  opacity: 0.8;
}

@keyframes scaleanimation {
  to {
    transform: translate(20px, -20px) scale(1.05);
    opacity: 1;
  }
}
@media only screen and (min-width:1200px) {
  .container {
    max-width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .container {
    max-width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .container {
    max-width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .container {
    max-width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .container {
    max-width: 400px;
  }
}
.card__badge--hidden {
  display: none;
}

.center{
  transform: translate(-50%, 0%);
  margin-left: 50%;
}

.centered-elt {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  /* height: 3.255rem; */
}
@media only screen and (min-width:1200px) {
  .centered-elt {
    width: 650px;
  }
}
@media only screen and (min-width:992px) and (max-width:1199px) {
  .centered-elt {
    width: 600px;
  }
}
@media only screen and (min-width:768px) and (max-width:991px) {
  .centered-elt {
    width: 500px;
  }
}
@media only screen and (max-width:767px) {
  .centered-elt {
    width: 380px;
  }
}
@media only screen and (min-width:576px) and (max-width:767px) {
  .centered-elt {
    width: 400px;
  }
}
.centered-elt ul {
  width: 100%;
}



.borderTop {
  border-top: 0.2rem solid #979693;
 
  border-top-radius: 10px;
  border-bottom: 0 solid #000;
/*   -webkit-box-shadow: 0px -18px 26px 0px rgba(151,150,147,0.74); 
  box-shadow: 0px -18px 26px 0px rgba(151,150,147,0.74); */
  
}

