/* Onboarding.css */

.onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    background-color: #181D3A;
  }
  
  .onboarding-image {
    max-width: 100%;
    margin-top:-25px;
    height: auto;
    z-index: 10;
  }
  
  .onboarding-buttons {
    position: absolute;

    bottom: 0px;
    z-index: 1000;
  }
  
  .onboarding-buttons button {
    padding: 10px 20px;
    font-size: 16px;
  }

  .play-btn{
    background: linear-gradient(to left, #39EB49, #57D48C);
    box-shadow: 0 0px 4px 5px rgba(57,235,73,0.5);
  
  }